import React from 'react';
import s2sLogo from '../assets/logos/s2s-logo.png';
import insurgoLogo from '../assets/logos/insurgo-logo.png';
import luLogo from '../assets/logos/lu-logo.png';
import absLogo from '../assets/logos/absolute-logo.png';

interface Partner {
  name: string;
  logo: string;
  website: string;
}

const partners: Partner[] = [
  {
    name: "S2S",
    logo: s2sLogo, 
    website: "https://www.s2s.uk.com",
  },
  {
    name: "Insurgo Media Services",
    logo: insurgoLogo, 
    website: "https://www.insurgo.com",
  },
  {
    name: "Loughborough University",
    logo: luLogo, 
    website: "https://www.lboro.ac.uk",
  },
  {
    name: "Absolute ITAD",
    logo: absLogo, 
    website: "https://www.absoluteitad.com",
  },
];

const Partners: React.FC = () => {
  return (
    <section className="py-20">
      <div className="container mx-auto px-6 text-center">
        <h2 className="text-4xl font-bold mb-12 text-primary-dark">Companies We’ve Worked With</h2>
        <div className="flex flex-wrap justify-center items-center gap-4"> 
          {partners.map((partner, index) => (
            <div 
              key={index} 
              className="flex items-center justify-center bg-white rounded-lg shadow-lg overflow-hidden w-full max-w-xs h-32 p-6"
            > 
              <a 
                href={partner.website} 
                target="_blank" 
                rel="noopener noreferrer" 
                className="flex items-center justify-center w-full h-full"
              >
                <img 
                  src={partner.logo} 
                  alt={partner.name} 
                  className="max-w-full h-full object-contain" 
                  style={{ paddingTop: partner.name === 'Loughborough University' ? '12px' : '0' }} 
                />
              </a>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};


export default Partners;
