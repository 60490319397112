import React from 'react';
import YoutubeEmbed from './YoutubeEmbed';
interface Section {
  title: string;
  image?: string;
  youtubeEmbed?: string;
  text: string;
}

interface CaseStudyProps {
  title: string;
  summary: string;
  results: string;
  image: string;
  sections: Section[];
}

const CaseStudy: React.FC<CaseStudyProps> = ({ title, summary, results, sections }) => {
  return (
    <div className="case-study bg-gray-100 py-8 px-6">
      <h1 className="text-4xl font-bold text-center text-primary-dark mb-6">{title}</h1>
      <p className="text-xl font-semibold text-center text-primary mb-4">{summary}</p>
      <p className="text-lg text-center text-primary-dark mb-12">{results}</p>

      {sections.map((section, index) => (
        <div
          key={index}
          className={`section flex flex-col md:flex-row items-center justify-between mb-12 ${
            index % 2 === 0 ? '' : 'md:flex-row-reverse'
          }`}
        >
          <div className="md:w-1/2 p-4">
            <h2 className="text-2xl font-semibold text-primary mb-4">{section.title}</h2>
            <p className="text-gray-700">{section.text}</p>
          </div>
          {section.image && (
            <div className="md:w-1/2 p-4 flex justify-center">
              <img
                src={section.image}
                alt={section.title}
                className="w-auto h-full max-h-60 object-cover rounded-lg shadow-lg"
              />
            </div>
          )}
          {section.youtubeEmbed && (
            <div className="md:w-1/2 p-4 flex justify-center">
              <YoutubeEmbed embedId={section.youtubeEmbed} width={480} height={300} title='section.title' />
            </div>
          )}
        </div>
      ))}
    </div>
  );
};

export default CaseStudy;
