// src/pages/Home.tsx
import React from 'react';
import Hero from '../components/Hero';
import Testimonials from 'components/Testimonials';
import Partners from 'components/Partners';
import Services from 'components/Services';
import Experience from 'components/Experience';
import AboutUs from 'components/AboutUs';
import ContactUs from 'components/ContactUs';

const Home: React.FC = () => {
    return (
        <div className="font-sans">
            <Hero />

            <div className="bg-gradient-to-r from-primary to-blue-400 text-white">
                <AboutUs />
            </div>

            <div className="bg-white">
                <Services />
            </div>

            <div className="bg-gradient-to-r from-offWhite to-gray-200">
                <Experience />
            </div>

            <div className="bg-white">
                <Testimonials />
            </div>

            <div className="bg-gradient-to-r from-primary-light to-primary">
                <Partners />
            </div>
            <div className="bg-white">
                <ContactUs />
            </div>
        </div>
    );
}




export default Home;
