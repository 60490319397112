import React from 'react';

interface CaseStudyTileProps {
  title: string;
  image: string;
  path: string;
  onClick: () => void;
}

const CaseStudyTile: React.FC<CaseStudyTileProps> = ({ title, image, onClick }) => {
  return (
    <div
      onClick={onClick}
      className="case-study-tile block bg-white rounded-lg shadow-lg overflow-hidden mb-4 cursor-pointer mx-2" // Add mx-2 for horizontal margin
    >
      <img src={image} alt={title} className="w-full h-48 px-4 object-contain" />
      <div className="p-4 text-center">
        <h3 className="text-xl font-semibold text-primary">{title}</h3>
      </div>
    </div>
  );
};

export default CaseStudyTile;
