// src/components/Experience.tsx
import React from 'react';

interface ExperienceItem {
  name: string;
  description: string;
}

const companyTypes: ExperienceItem[] = [
  {
    name: "SMEs",
    description: "Helping small and medium-sized businesses access cost-effective, cutting-edge technology."
  },
  {
    name: "Manual Processing",
    description: "Providing traceability solutions for hard-to-monitor manual processes, enhancing efficiency and accuracy."
  },
  {
    name: "Startups",
    description: "Assisting startups in creating innovative prototypes and demonstrators to bring new ideas to life."
  },
];


const industries: ExperienceItem[] = [
  { 
    name: "Manufacturing", 
    description: "Providing advanced traceability systems that streamline production processes, improve efficiency, and ensure quality control throughout the manufacturing lifecycle."
  },
  { 
    name: "Refurbishment & Recycling", 
    description: "Developing solutions to track and manage the refurbishment and recycling process, ensuring compliance, accountability, and sustainability from start to finish."
  },
  { 
    name: "Universities", 
    description: "Partnering with universities to create research-driven technology solutions, enhancing the capabilities of academic institutions."
  },
  { 
    name: "Sport Technology", 
    description: "Innovating in sports technology by designing and implementing electronics that can perform as novel aids to optimise training processes for athletes and teams."
  },
];

const Experience: React.FC = () => {
  return (
    <section className="py-20">
      <div className="container mx-auto px-6 text-center">
        <h2 className="text-4xl font-bold mb-12 text-primary-dark">Our Experience</h2>
        <p className="text-lg text-gray-700 mb-12">
          We have experience working with various types of companies and across a range of industries.
        </p>

        {/* Types of Companies Section */}
        <div className="mb-12">
          <h3 className="text-3xl font-semibold mb-8 text-primary-dark">Types of Companies</h3>
          <div className="flex flex-wrap justify-center gap-8">
            {companyTypes.map((type, index) => (
              <div 
                key={index} 
                className="bg-white rounded-lg shadow-lg p-6 flex-grow max-w-xs sm:max-w-sm md:max-w-md lg:max-w-lg"
              >
                <h4 className="text-xl font-bold mb-2 text-primary">{type.name}</h4>
                <p className="text-gray-700">{type.description}</p>
              </div>
            ))}
          </div>
        </div>

        {/* Industries Served Section */}
        <div className="mb-12">
          <h3 className="text-3xl font-semibold mb-8 text-primary-dark">Industries Served</h3>
          <div className="flex flex-wrap justify-center gap-8">
            {industries.map((industry, index) => (
              <div 
                key={index} 
                className="bg-white rounded-lg shadow-lg p-6 flex-grow max-w-xs sm:max-w-sm md:max-w-md lg:max-w-lg"
              >
                <h4 className="text-xl font-bold mb-2 text-primary">{industry.name}</h4>
                <p className="text-gray-700">{industry.description}</p>
              </div>
            ))}
          </div>
        </div>
      </div>
    </section>
  );
};


export default Experience;
