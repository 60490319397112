// src/components/Navbar.tsx
import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import logo from '../assets/logos/exine-logo-white.png';
const Navbar: React.FC = () => {
    const [isOpen, setIsOpen] = useState(false);
  
    const toggleMenu = () => {
      setIsOpen(!isOpen);
    };
  
    return (
      <nav className="bg-gray-800 p-4">
        <div className="container mx-auto flex justify-between items-center">
          <div className="flex items-center">
            <Link to="/">
              <img src={logo} alt="Exine Technology Logo" className="h-10" />
            </Link>
          </div>
          <div className="hidden md:flex space-x-4">
            <Link to="/" className="text-white hover:bg-slate-300 px-3 py-2 rounded">Home</Link>
            <Link to="/about" className="text-white hover:bg-slate-300 px-3 py-2 rounded">About</Link>
            <Link to="/case-studies" className="text-white hover:bg-slate-300 px-3 py-2 rounded">Case Studies</Link>
          </div>
          <div className="md:hidden">
            <button onClick={toggleMenu} className="text-white focus:outline-none">
              ☰
            </button>
          </div>
        </div>
        {isOpen && (
          <div className="md:hidden">
            <div className="px-2 pt-2 pb-3 space-y-1 sm:px-3">
              <Link to="/" className="text-white hover:bg-gray-700 block px-3 py-2 rounded">Home</Link>
              <Link to="/about" className="text-white hover:bg-gray-700 block px-3 py-2 rounded">About</Link>
              <Link to="/case-studies" className="text-white hover:bg-gray-700 block px-3 py-2 rounded">Case Studies</Link>
            </div>
          </div>
        )}
      </nav>
    );
  };
  
  export default Navbar;