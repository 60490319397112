import React from "react";
interface YoutubeProps {
    embedId: string;
    width?: number;
    height?: number;
    title?:string;
}

const YoutubeEmbed: React.FC<YoutubeProps> = ({embedId, width, height, title}) => {
  return (<div className="video-responsive">
    <iframe
      width= {width ? width : 853}
      height= {height ? height : 480}
      src={`https://www.youtube.com/embed/${embedId}`}
      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
      allowFullScreen
      title={title ? title : "Embedded Youtube"}
    />
  </div>
  );
};

export default YoutubeEmbed;