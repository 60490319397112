import React from 'react';

const AboutUs: React.FC = () => {
    return (
        <section className="py-16">
            <div className="container mx-auto px-6 text-center max-w-5xl"> {/* Increased max width */}
                <p className="text-xl md:text-2xl lg:text-3xl font-light leading-loose mb-6">
                    At Exine Technology, we combine our engineering expertise with cutting-edge technology to deliver traceability systems tailored to your business needs.
                </p>
                <p className="text-xl md:text-2xl lg:text-3xl font-light leading-loose">
                    We pride ourselves on understanding your unique processes and delivering solutions that improve efficiency and accuracy.
                </p>
            </div>
        </section>
    );
};

export default AboutUs;
