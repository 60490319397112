import React, { useState } from 'react';
// import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import CaseStudy from '../components/CaseStudy';
import CaseStudyTile from '../components/CaseStudyTile';
import Slider from 'react-slick';

// Importing JSON files
import insurgoDrive from '../case-studies/insurgoDrive.json';
import investigo from '../case-studies/investigo.json';
import s2s from '../case-studies/s2s-wb3.json';
// Slick Carousel CSS
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

const caseStudies = [
  {
    data: investigo,
    path : 'investigo'
  },
  {
    data: insurgoDrive,
    path: 'insurgo-drive',
  },
  {
    data: s2s,
    path: 's2s',
  },

];

const CaseStudies: React.FC = () => {
  const [selectedCaseStudy, setSelectedCaseStudy] = useState(caseStudies[0].data);

  // Settings for the react-slick carousel
  const settings = {
    dots: true,
    infinite: false,
    speed: 500,
    slidesToShow: 5, // Number of slides to show on wide screens
    slidesToScroll: 1,
    arrows: true, // Enable arrows for navigation
    responsive: [
      {
        breakpoint: 1024, // Adjust at tablet size
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          infinite: false,
          dots: true,
        },
      },
      {
        breakpoint: 600, // Adjust at mobile size
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          initialSlide: 2,
          infinite: false,
          dots: true,
        },
      },
      {
        breakpoint: 480, // Adjust at small mobile size
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: false,
          dots: true,
        },
      },
    ],
  };

  return (
    <div className="case-studies-page bg-white py-20">
       <div className="container mx-auto px-6">
        <h1 className="text-4xl font-bold text-center text-primary-dark mb-12">Our Case Studies</h1>

        {/* Carousel for case study tiles */}
        <Slider {...settings}>
          {caseStudies.map((study, index) => (
            <CaseStudyTile
              key={index}
              title={study.data.title}
              image={study.data.image}
              path={study.path}
              onClick={() => setSelectedCaseStudy(study.data)} // Update selected case study
            />
          ))}
        </Slider>
          <div style={{ marginTop: '20px' }}>
        {/* Render the selected case study */}
        {selectedCaseStudy && (
          <CaseStudy
            title={selectedCaseStudy.title}
            summary={selectedCaseStudy.summary}
            results={selectedCaseStudy.results}
            sections={selectedCaseStudy.sections}
            image={selectedCaseStudy.image}
          />
        )}
         </div>
      </div>
    </div>
  );
};

export default CaseStudies;