import React, { useEffect, useState } from "react";
interface NumberSpinnerProps {
    totalNumber: number;
    startNumber: number;
    timespan: number;
}

const NumberSpinner: React.FC<NumberSpinnerProps> = ({totalNumber, startNumber, timespan}) => {
    const [count, setCount] = useState(0);
  
    useEffect(() => {
      let startTimestamp = 0;
      const increment = (totalNumber-startNumber) / (timespan * 1000); // Total amount divided by duration in ms
  
      const animateCount = (timestamp: number) => {
        if (!startTimestamp) startTimestamp = timestamp;
        const progress = timestamp - startTimestamp;
        const newCount = Math.min(totalNumber-startNumber, Math.floor(progress * increment))+startNumber;
        
        if(timespan === 0) {
            setCount(totalNumber);
        } else {
            setCount(newCount);
        }
  
        if (progress < timespan * 1000) {
          requestAnimationFrame(animateCount); // Continue the animation
        }
      };
  
      requestAnimationFrame(animateCount); // Start the animation
    }, [totalNumber, timespan, startNumber]);
    return (
    <div>
        <h1>{count.toLocaleString()}</h1> {/* Formats the number nicely */}
    </div>
    );
};

export default NumberSpinner;