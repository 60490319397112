// src/components/ContactUs.tsx
import React from "react";

const ContactUs: React.FC = () => {
  return (
    <section className="bg-gray-100 py-20">
      <div className="container mx-auto px-6">
        <h2 className="text-4xl font-bold text-center text-primary-dark mb-8">
          Contact Us
        </h2>
        <p className="text-lg text-center text-gray-700 mb-12">
          Ready to take your traceability to the next level? Contact us today to
          discuss your needs and see how Exine Technology can help you achieve
          your goals.
        </p>
        <div className="text-center">
          <a href="mailto:hello@exine.com?subject=Inquiry about Traceability Systems">
            <button
              className="bg-primary text-white font-bold py-3 px-6 rounded-full hover:bg-primary-dark transition duration-300"
              type="submit"
            >
              Send Message
            </button>
          </a>
        </div>
      </div>
    </section>
  );
};

export default ContactUs;
