// src/components/Footer.tsx
import React from 'react';
import logo from '../assets/logos/exine-logo-white.png';

const Footer: React.FC = () => {
  return (
    <footer className="bg-gray-800 text-white py-6">
      <div className="container mx-auto text-center">
        <img src={logo} alt="Exine Logo" className="h-10 mx-auto mb-4" />
        <p className="text-sm">&copy; {(new Date().getFullYear())} Exine Technology Ltd. All rights reserved.</p>
        <p className="text-sm">Company Number: 11702737 | VAT: GB316011555</p>
      </div>
    </footer>
  );
};

export default Footer;
