import React from "react";
import electronicsImg from "../assets/images/services/electronics2_sm.jpg";
import webDevImg from "../assets/images/services/web-dev_sm.jpg";
import uxImg from "../assets/images/services/ux_sm.jpg";

interface Service {
  title: string;
  description: string;
  image: string;
}

const services: Service[] = [
  {
    title: "Web Application Development",
    description:
      "We build responsive, scalable, and secure web applications tailored to your business needs. Our solutions integrate intuitive user interfaces with reliable backend systems, ensuring performance and growth.",
    image: webDevImg,
  },
  {
    title: "Electronics Design",
    description:
      "We specialise in designing, prototyping and manufacturing custom electronics, from circuit boards to firmware, including 3D-printed enclosures for complete, ready-to-manufacture solutions.",
    image: electronicsImg,
  },
  {
    title: "UI/UX Design",
    description:
      "We craft user-friendly interfaces that enhance user engagement and experience, ensuring that your digital products are not only functional but also a delight to use.",
    image: uxImg,
  },
];

const Services: React.FC = () => {
  return (
    <section className="py-20">
      <div className="container mx-auto px-6 text-center">
        <h2 className="text-4xl font-bold mb-12 text-primary-dark">
          Our Services
        </h2>
        <p className="text-xl md:text-xl lg:text-xl text-gray-800 font-light leading-loose max-w-5xl mx-auto">
          Our traceability systems are designed to fit your business. Whether
          you need to monitor the movement of assets across a factory floor or
          track the lifecycle of products from creation to distribution, we
          build web apps that give you complete visibility and control.
        </p>
        <br />
        <p className="text-xl md:text-xl lg:text-xl text-gray-800 font-light leading-loose max-w-5xl mx-auto">
          What sets us apart is our ability to deliver solutions that are more
          than just bespoke. Built upon our extensive in-house libraries and
          development work, our systems are highly customisable, allowing us to
          create cost-effective solutions for SMEs and businesses of all sizes.
          Even if you thought such advanced traceability systems were out of
          reach, Exine Technology makes them attainable, empowering your
          operations with affordable, cutting-edge technology.
        </p>
        <br />
        <p className="text-xl md:text-xl lg:text-xl text-gray-800 font-light leading-loose max-w-5xl mx-auto">
          Coupled with our expertise in RFID and NFC technology, we ensure every
          physical asset is seamlessly integrated into your digital management
          system.
        </p>
        <br />
        <div className="flex flex-wrap justify-center items-stretch py-3">
          {services.map((service, index) => (
            <div
              key={index}
              className="flex flex-col max-w-sm w-full bg-white rounded-lg shadow-lg overflow-hidden mx-4 mb-6"
            >
              <div className="flex-shrink-0">
                <img
                  className="w-full h-48 object-cover"
                  src={service.image}
                  alt={service.title}
                />
              </div>
              <div className="flex flex-col flex-grow p-6 justify-start">
                {" "}
                {/* Align items at the top */}
                <h3 className="text-xl font-bold mb-4 text-primary">
                  {service.title}
                </h3>
                <p className="text-gray-700">{service.description}</p>
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default Services;
