import React from 'react';
import { Link } from 'react-router-dom';
import AssetStats from './AssetStats';

const Hero: React.FC = () => {
  return (
    <section className="bg-custom-background text-black py-10">
      <div className="container mx-auto flex flex-col-reverse md:flex-row items-center justify-between px-6">
        <div style={{ zIndex: 2}} className="flex flex-col justify-center items-start text-center md:text-left md:w-1/2">
        <h1 className="text-5xl md:text-6xl font-bold leading-tight mb-4">
          Traceability Systems for Industry
        </h1>
        <p className="text-xl md:text-2xl mb-6">
          Exine Technology bridges the gap between your physical assets and digital management systems, providing the data you need to optimise your processes.
        </p>
        <div className="container mx-auto px-6 text-center max-w-5xl mb-6"> {/* Increased max width */}
            <div className="flex justify-center items-center">
            <AssetStats />
            </div>
        </div>
        <div className="container mx-auto px-6 text-center max-w-5xl py-6"> {/* Increased max width */}
            <div className="flex justify-center items-center">
              <Link to="/case-studies" className="bg-primary hover:bg-primary-dark text-white font-bold py-5 px-6 rounded-full">
                Learn More About Our Work
              </Link>
            </div>
        </div>
      </div>
      <div className="tech-illustration md:w-1/2 w-full h-full">
        {/* Adjust your image size here */}
        {/* <img src={'path-to-your-image'} alt="Tech Illustration" className="object-cover w-full h-auto md:h-auto"/> */}
      </div>
    </div>
    
    </section >
  );
};

export default Hero;
